/* common.js */

(function($){
    $(document).ready(function(){

        $('.navbar__toggler').click(function() {
            $('body').removeClass('search-active');
            $('.search-mobile-button').removeClass('active').attr("aria-expanded","false");

            if ($(this).hasClass('collapsed')) {
                $(this).toggleClass('collapsed').attr("aria-expanded","true");
                $('body').toggleClass('menu-active');
            } else {
                $(this).toggleClass('collapsed').attr("aria-expanded","false");
                $('body').toggleClass('menu-active');
            }
        });

        $('.search-mobile-button').click(function() {
            $('body').removeClass('menu-active');
            $('.navbar__toggler').addClass('collapsed').attr("aria-expanded","false");

            if ($(this).hasClass('active')) {
                $(this).toggleClass('active').attr("aria-expanded","false");
                $('body').toggleClass('search-active');
            } else {
                $(this).toggleClass('active').attr("aria-expanded","true");
                $('body').toggleClass('search-active');
            }
        });

        calculateHeader();

        $('.menu-item-has-children > a').click(function (e) {
            let viewportWidth = $(window).width();

            if (viewportWidth < 768) {
                e.preventDefault();

                if ($(this).parent('.menu-item-has-children').hasClass('menu-item-has-children--active')) {
                    $(this).parent('.menu-item-has-children').removeClass('menu-item-has-children--active');
                } else {
                    $('.menu-item-has-children').removeClass('menu-item-has-children--active');
                    $(this).parent('.menu-item-has-children').addClass('menu-item-has-children--active');
                }
            }
        });

        $('.entry-content table').wrap("<div class='mobile-table-wrap'></div>");
    });

    $( window ).resize(function() {
        calculateHeader();
    });

    $( window ).scroll(function() {
        calculateHeader();
    });

    function calculateHeader() {
        let viewportHeight = $(window).height();
        let outerHeight = window.outerHeight;
        let innerHeight = window.innerHeight;

        $('.navbar').height(viewportHeight);
        $('#menu-main-menu').css('max-height', viewportHeight);

        setTimeout(function(){
            let viewportHeight = $(window).height();
            $('.navbar').height(viewportHeight);
            $('#menu-main-menu').css('max-height', viewportHeight);
        }, 3000);
    }
})(jQuery);
